<!--  -->
<template>
  <div>
    <template v-if="!$attrs.isCollect">
      <div
        class="btnBox"
        v-if="!(student_user_id && student_user_id + '' !== userInfo.id + '')"
      >
        <div class="tow" @click="$handleRoute('/me/speed')">查看学习进度</div>
      </div>
      <div>
        <!-- 我的课程/他的课程 -->
        <div class="box">
          <el-table
            v-loading="loading"
            ref="singleTable"
            :data="courseList"
            highlight-current-row
            @row-click="
              $handleRoute(
                { course_id: $event.id, tid: $event.tid || '' },
                'courseDetail'
              )
            "
            style="width: 100%"
          >
            <el-table-column
              property="name"
              align="center"
              label="课程名称"
              width="220"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <div class="title">{{ scope.row.course_name }}</div>
              </template>
            </el-table-column>

            <el-table-column align="center" label="时间">
              <template slot-scope="scope">
                <div class="founder">{{ scope.row._create_time }}</div>
              </template>
            </el-table-column>
            <el-table-column align="center" label="课时">
              <template slot-scope="scope">
                <div class="teamNum">{{ scope.row.class_hour }}</div>
              </template>
            </el-table-column>

            <!-- <el-table-column
                property="address"
                align="center"
                label="知识点"
                width="200"
            >
              <template slot-scope="scope">
                <el-tooltip
                    effect="dark"
                    :content="scope.row.description.substring(0, 100)"
                    placement="top"
                    v-if="scope.row.description.length > 10"
                >
              <span class="ProblemList-name">{{
                  scope.row.description.substring(0, 10) + "..."
                }}</span>
                </el-tooltip>
              </template>
            </el-table-column> -->
            <el-table-column align="center" label="讲师">
              <template slot-scope="scope">
                <div class="time">{{ scope.row.teach_name }}</div>
              </template>
            </el-table-column>
            <el-table-column align="center" label="学习人数">
              <template slot-scope="scope">
                <div class="time">{{ scope.row.buy_num }}</div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="操作"
              v-if="userInfo.type === 2"
            >
              <template slot-scope="scope">
                <div
                  class="button-primary"
                  :style="{ width: '100%' }"
                  v-href="{
                    name: 'teachingCourseEdit',
                    query: { course_id: scope.row.id },
                  }"
                  @click.stop="
                    $handleRoute(
                      { course_id: scope.row.id },
                      'teachingCourseEdit'
                    )
                  "
                  v-if="scope.row.user_id + '' === userInfo.id + ''"
                >
                  编辑
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </template>
    <template v-else>
      <com-table
        ref="table"
        :data="{
          url: '/api/v1_0_0.course/collect_list',
          params: {
            ...(this.$attrs.params || {}),
            page,
            page_size,
            student_user_id,
          },
        }"
        :total.sync="total"
        :empty-text="'暂无课程'"
        :auto-load="false"
        list-type="table-list"
        :columns="[]"
        :page-size="page_size"
        :row-page="3"
        :page-options="false"
        @done="loading = false"
      >
        <template slot-scope="{ row }">
          <div style="padding: 0 10px; margin-bottom: 15px">
            <el-card
              :body-style="{ padding: 0 }"
              :style="{ borderRadius: '15px 15px 0 0' }"
              v-if="$route.name === 'meStudyColletCoursePlan'"
            >
              <div style="width: 100%; height: 200px; position: relative">
                <el-image
                  fit="contain"
                  :src="row.cover"
                  style="width: 100%; height: 100%"
                  v-href="{
                    name: 'teachPlanCourseDetail',
                    query: { course_id: row.id },
                  }"
                >
                </el-image>
              </div>
              <div
                :style="{
                  padding: '8px 14px',
                  borderBottom: '1px solid #e6e6e6',
                }"
              >
                <div
                  class="shenluehao-2"
                  :style="{
                    height: '48px',
                    lineHeight: '24px',
                    cursor: 'pointer',
                  }"
                >
                  <span
                    v-href="{
                      name: 'teachPlanCourseDetail',
                      query: { course_id: row.id },
                    }"
                    >{{ row.course_name }}</span
                  >
                </div>
                <div
                  :style="{
                    fontSize: '90%',
                    lineHeight: 1.5,
                    color: 'rgba(254,81,52,1)',
                  }"
                  :data-price="
                    (row.show_price =
                      row.up_types.indexOf(1) > -1 ? row.price : row.price2)
                  "
                >
                  {{ row.show_price > 0 ? "¥" + row.show_price : "免费" }}
                </div>
                <div
                  :style="{
                    marginTop: '10px',
                    fontSize: '90%',
                    lineHeight: 1.5,
                    color: 'rgb(136, 136, 136)',
                  }"
                >
                  {{ row.cid_name }} · {{ row.buy_num || 0 }}人购买
                </div>
              </div>
              <div :style="{ padding: '8px 14px' }">
                <div style="display: flex; line-height: 40px">
                  <el-avatar :src="row.teach_headimg"></el-avatar>
                  <div
                    style="margin-left: 5px; font-weight: 700; flex: 1"
                    class="shenluehao"
                  >
                    {{ row.teach_name }}
                  </div>
                </div>
              </div>
            </el-card>
            <CourseItem :item="row" v-else></CourseItem>
          </div>
        </template>
      </com-table>
    </template>
    <!-- 分页 -->

    <div class="page" :style="$attrs.pageStyle || {}">
      <el-pagination
        background
        layout="total,sizes,prev, pager, next"
        :total="total"
        @current-change="getCourseList"
        @size-change="onSizeChange"
        :page-size="page_size"
        :current-page="page"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import CourseItem from "@/components/CourseItem/CourseItem.vue";
import { course, collect_list } from "@/api/me.js";

export default {
  props: {
    student_user_id: {
      default: () => 0,
    },
  },
  //import引入的组件需要注入到对象中才能使用
  components: { CourseItem },
  data() {
    //这里存放数据
    return {
      loading: false,
      // 课程列表
      courseList: [],
      page: 1,
      page_size: 10,
      total: 0,
    };
  },
  mounted() {
    // 课程列表
    this.getCourseList();
  },
  //方法集合
  methods: {
    getCourseList(page) {
      this.page = page || 1;
      this.loading = true;
      if (this.$attrs.isCollect) {
        this.$nextTick(() => {
          this.$refs.table.handlePageChange(this.page);
        });
      } else {
        course({
          page: this.page,
          page_size: this.page_size,
          student_user_id: this.student_user_id,
        })
          .then((res) => {
            // console.log(res, "resssssssss");
            this.courseList = (res.data || {}).data;
            this.total = res.data.total;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  onSizeChange(pageSize) {
    this.page_size = pageSize;
    this.getCourseList();
  },
  },
};
</script>
<style lang="less" scoped>
.box {
  border: 1px solid #979797;
  .img {
    width: 20px;
    height: 20px;
    img {
      display: inline-block;
      width: 100%;
      height: 100%;
    }
  }

  .founder,
  .teamNum， .class,
  .time {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #363636;
  }
  .title {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #01957e;
  }
}
.btnBox {
  display: flex;
  justify-content: flex-end;
}
.tow {
  margin-bottom: 1rem;
  cursor: pointer;
  background: #00957e;
  border-radius: 2px;
  color: #fff;
  height: 20px;
  line-height: 20px;
  padding: 4px 10px;
  font-size: 12px;

  display: inline-block;
}
</style>

