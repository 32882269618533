var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.$attrs.isCollect)?[(!(_vm.student_user_id && _vm.student_user_id + '' !== _vm.userInfo.id + ''))?_c('div',{staticClass:"btnBox"},[_c('div',{staticClass:"tow",on:{"click":function($event){return _vm.$handleRoute('/me/speed')}}},[_vm._v("查看学习进度")])]):_vm._e(),_c('div',[_c('div',{staticClass:"box"},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"singleTable",staticStyle:{"width":"100%"},attrs:{"data":_vm.courseList,"highlight-current-row":""},on:{"row-click":function($event){return _vm.$handleRoute(
              { course_id: $event.id, tid: $event.tid || '' },
              'courseDetail'
            )}}},[_c('el-table-column',{attrs:{"property":"name","align":"center","label":"课程名称","width":"220","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"title"},[_vm._v(_vm._s(scope.row.course_name))])]}}],null,false,1505628978)}),_c('el-table-column',{attrs:{"align":"center","label":"时间"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"founder"},[_vm._v(_vm._s(scope.row._create_time))])]}}],null,false,1583721575)}),_c('el-table-column',{attrs:{"align":"center","label":"课时"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"teamNum"},[_vm._v(_vm._s(scope.row.class_hour))])]}}],null,false,3023833037)}),_c('el-table-column',{attrs:{"align":"center","label":"讲师"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"time"},[_vm._v(_vm._s(scope.row.teach_name))])]}}],null,false,3139247585)}),_c('el-table-column',{attrs:{"align":"center","label":"学习人数"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"time"},[_vm._v(_vm._s(scope.row.buy_num))])]}}],null,false,4261816965)}),(_vm.userInfo.type === 2)?_c('el-table-column',{attrs:{"align":"center","label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.user_id + '' === _vm.userInfo.id + '')?_c('div',{directives:[{name:"href",rawName:"v-href",value:({
                  name: 'teachingCourseEdit',
                  query: { course_id: scope.row.id },
                }),expression:"{\n                  name: 'teachingCourseEdit',\n                  query: { course_id: scope.row.id },\n                }"}],staticClass:"button-primary",style:({ width: '100%' }),on:{"click":function($event){$event.stopPropagation();return _vm.$handleRoute(
                    { course_id: scope.row.id },
                    'teachingCourseEdit'
                  )}}},[_vm._v(" 编辑 ")]):_vm._e()]}}],null,false,857085100)}):_vm._e()],1)],1)])]:[_c('com-table',{ref:"table",attrs:{"data":{
        url: '/api/v1_0_0.course/collect_list',
        params: {
          ...(this.$attrs.params || {}),
          page: _vm.page,
          page_size: _vm.page_size,
          student_user_id: _vm.student_user_id,
        },
      },"total":_vm.total,"empty-text":'暂无课程',"auto-load":false,"list-type":"table-list","columns":[],"page-size":_vm.page_size,"row-page":3,"page-options":false},on:{"update:total":function($event){_vm.total=$event},"done":function($event){_vm.loading = false}},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('div',{staticStyle:{"padding":"0 10px","margin-bottom":"15px"}},[(_vm.$route.name === 'meStudyColletCoursePlan')?_c('el-card',{style:({ borderRadius: '15px 15px 0 0' }),attrs:{"body-style":{ padding: 0 }}},[_c('div',{staticStyle:{"width":"100%","height":"200px","position":"relative"}},[_c('el-image',{directives:[{name:"href",rawName:"v-href",value:({
                  name: 'teachPlanCourseDetail',
                  query: { course_id: row.id },
                }),expression:"{\n                  name: 'teachPlanCourseDetail',\n                  query: { course_id: row.id },\n                }"}],staticStyle:{"width":"100%","height":"100%"},attrs:{"fit":"contain","src":row.cover}})],1),_c('div',{style:({
                padding: '8px 14px',
                borderBottom: '1px solid #e6e6e6',
              })},[_c('div',{staticClass:"shenluehao-2",style:({
                  height: '48px',
                  lineHeight: '24px',
                  cursor: 'pointer',
                })},[_c('span',{directives:[{name:"href",rawName:"v-href",value:({
                    name: 'teachPlanCourseDetail',
                    query: { course_id: row.id },
                  }),expression:"{\n                    name: 'teachPlanCourseDetail',\n                    query: { course_id: row.id },\n                  }"}]},[_vm._v(_vm._s(row.course_name))])]),_c('div',{style:({
                  fontSize: '90%',
                  lineHeight: 1.5,
                  color: 'rgba(254,81,52,1)',
                }),attrs:{"data-price":(row.show_price =
                    row.up_types.indexOf(1) > -1 ? row.price : row.price2)}},[_vm._v(" "+_vm._s(row.show_price > 0 ? "¥" + row.show_price : "免费")+" ")]),_c('div',{style:({
                  marginTop: '10px',
                  fontSize: '90%',
                  lineHeight: 1.5,
                  color: 'rgb(136, 136, 136)',
                })},[_vm._v(" "+_vm._s(row.cid_name)+" · "+_vm._s(row.buy_num || 0)+"人购买 ")])]),_c('div',{style:({ padding: '8px 14px' })},[_c('div',{staticStyle:{"display":"flex","line-height":"40px"}},[_c('el-avatar',{attrs:{"src":row.teach_headimg}}),_c('div',{staticClass:"shenluehao",staticStyle:{"margin-left":"5px","font-weight":"700","flex":"1"}},[_vm._v(" "+_vm._s(row.teach_name)+" ")])],1)])]):_c('CourseItem',{attrs:{"item":row}})],1)]}}])})],_c('div',{staticClass:"page",style:(_vm.$attrs.pageStyle || {})},[_c('el-pagination',{attrs:{"background":"","layout":"total,sizes,prev, pager, next","total":_vm.total,"page-size":_vm.page_size,"current-page":_vm.page},on:{"current-change":_vm.getCourseList,"size-change":_vm.onSizeChange}})],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }